
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import RootComponentModel from '@/types/common/root-component-model';
    import { CheckboxFilterMixin } from '@entrypointdev/datatables.net-vue';


    @Component
    export default class FilterCheckbox extends Mixins(CheckboxFilterMixin) {
        readonly $root!: RootComponentModel;

         //checked - mixed from mixin (see source)

        @Prop({ type: String })
        protected size!: string;
    }
