import { Role, RoleNames } from "../components/views/admin/users/constants/role-names";

const routeOptions = [
    { path: '/', name: 'default', view: 'home', display: 'Home', meta: { showInMenu: false } },
    { path: '/401-forbidden', name: 'forbidden', view: 'errors/401-forbidden', display: '401 Forbidden' },
    { path: '/404-not-found', name: 'not-found', view: 'errors/404-not-found', display: '404 Page Not Found' },
    { path: '/login', name: 'login', view: 'auth/login' },
    { path: '/auth/forgot-password', view: 'auth/forgot-password' },
    { path: '/auth/reset-password', view: 'auth/reset-password', props: (route) => ({ code: route.query.code }) },
    { path: '/auth/confirm-email', view: 'auth/confirm-email', props: (route) => ({ userId: route.query.userId, token: route.query.token }) },
    { path: '/admin/user-list', view: 'admin/users/user-list', display: 'Users', meta: { showInMenu: true, auth: { roles: RoleNames.get(Role.SuperAdmin) } } },
    { path: '/quiz', view: 'quiz/quiz', display: 'CWT', meta: { showInMenu: true, auth: { roles: [RoleNames.get(Role.DistrictAdmin), RoleNames.get(Role.BuildingAdmin)] } } },
    { path: '/admin/districts', view: 'admin/districts/district-list', display: 'Districts', meta: { showInMenu: true, auth: { roles: RoleNames.get(Role.SuperAdmin) } } },
    { path: '/admin/schools', view: 'admin/schools/school-list', display: 'Schools', meta: { showInMenu: true, auth: { roles: RoleNames.get(Role.SuperAdmin) } } },
    { path: '/admin/teachers', view: 'admin/teachers/teacher-list', display: 'Teachers', meta: { showInMenu: true, auth: { roles: RoleNames.get(Role.SuperAdmin) } } },
    { path: '/stats', view: 'stats/stats', display: 'Results', meta: { showInMenu: true, auth: { roles: [RoleNames.get(Role.SuperAdmin), RoleNames.get(Role.DistrictAdmin), RoleNames.get(Role.BuildingAdmin)] } } },
    { path: '/analytics', view: 'analytic/analytic', display: 'Analytics', meta: { showInMenu: true, auth: { roles: [RoleNames.get(Role.SuperAdmin), RoleNames.get(Role.DistrictAdmin)] } } },
    {
        path: '/profile',
        view: 'profile/profile',
        display: 'Edit profile',
        meta: { auth: true },
        children: [
            {
                path: '',
                display: 'My Profile',
                view: 'profile/edit-profile',
                meta: { auth: true }
            },
            {
                path: 'manage-logins',
                display: 'External Logins',
                view: 'profile/manage-logins',
                meta: { auth: true }
            },
            {
                path: 'change-password',
                display: 'Change Password',
                view: 'profile/change-password',
                meta: { auth: true }
            },
            {
                path: 'set-password',
                display: 'Set Password',
                view: 'profile/set-password',
                meta: { auth: true }
            }
        ]
    },

    { path: '*', redirect: { name: 'not-found' } }
];

function addDynamicImport(route) {
    if (!route.view)
        return route;

    if (route.children && route.children.length) {
        route.children = route.children.map(child => {
            return addDynamicImport(child);
        });
    }

    return {
        ...route,
        component: () => import(/* webpackChunkName: "[request]" */ `@/components/views/${route.view}`)
    }
}

const routes = routeOptions.map(route => {
    return addDynamicImport(route);
})

export { routes }