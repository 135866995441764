import moment from 'moment';

const Utils = {
    getWeekRange(num) {
        return [this.getWeekStart(num).toDate(), this.getWeekEnd(num).toDate()];
    },

    getWeekStart(num) {
        return moment().subtract(num, 'weeks').startOf('isoWeek');
    },

    getWeekEnd(num) {
        return moment().subtract(num, 'weeks').endOf('isoWeek');
    },

    getMonthRange(num) {
        return [this.getMonthStart(num).toDate(), this.getMonthEnd(num).toDate()];
    },

    getMonthStart(num) {
        return moment().subtract(num, 'month').startOf('month');
    },

    getMonthEnd(num) {
        return moment().subtract(num, 'month').endOf('month');
    }
};

export default Utils;