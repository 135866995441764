
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import RootComponentModel from '@/types/common/root-component-model';
    import { InputFilterMixin } from '@entrypointdev/datatables.net-vue';

    @Component
    export default class FilterInput extends Mixins(InputFilterMixin) {
        readonly $root!: RootComponentModel;

        //debounce - used by mixin (see source)
        //query - mixed from mixin (see source)
        //debounceQuery - mixed from mixin (see source)

        @Prop({ type: String, default: '' })
        protected placeholder!: string;

        @Prop({ type: String })
        protected size!: string;
    }
