import moment from 'moment';
import Utils from '@/utils/date-utils';

const DATE_FORMAT = 'MM/DD/YYYY';
const MONTH_FORMAT = 'MMMM YYYY';
const DATE_RANGE_SPLITTER = '-';

const DEFAULT_RANGES = {
    'Today': [moment().toDate(), moment().toDate()],
    'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
    'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
    'This Month': Utils.getMonthRange(0),
    'Last Month': Utils.getMonthRange(1)
};

export { DATE_FORMAT, MONTH_FORMAT, DATE_RANGE_SPLITTER, DEFAULT_RANGES };