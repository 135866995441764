
    import { Component, Vue, Prop } from 'vue-property-decorator';
    @Component
    export default class LoadingBtn extends Vue {
        @Prop({ type: Boolean, required: true })
        loading!: boolean;
        @Prop({ type: String, default: 'primary' })
        variant!: string;
        @Prop({ type: String, default: 'button' })
        type!: string;
        handleClick(e) {
            this.$emit('click', e);
        }
    }
