
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import { SelectFilterMixin } from '@entrypointdev/datatables.net-vue';
    @Component
    export default class FilterSelect extends Mixins(SelectFilterMixin) {
        @Prop({ type: String, default: 'Select one' })
        placeholder!: string;

        @Prop({ type: String })
        size?: string;
        @Prop({ type: Boolean, default: false })
        allowEmpty?: boolean;
        get deselectLabel() {
            return this.allowEmpty ? 'Press enter to remove' : "Can't remove";
        }
    }
