
    import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
    import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

    @Component
    export default class FormGroupTemplate extends Mixins(singleErrorExtractorMixin) {
        @Prop({ type: String, default: null })
        labelFor!: string;
        @Prop({ type: Boolean, default: false })
        hideLabel!: boolean;
        @Prop({ type: Boolean, default: false })
        forceRequiredLabel!: boolean;
        @Prop({ type: Boolean, default: false })
        hideErrorMessage!: boolean;
        labelForAttributeValue: string = '';
        @Watch('labelFor')
        labelForWatcher() {
            this.setLabelForAttributeValue();
        }
        mounted() {
            this.$nextTick(() => {
                this.setLabelForAttributeValue();
            });
        }

        updated() {
            this.$nextTick(() => {
                this.setLabelForAttributeValue();
            });
        }

        get attributes() {
            return {
                class: { 'form-control': true, 'is-invalid': this.hasErrors, 'is-valid': this.isValid },
                name: this.name || undefined
            }
        }

        get showLabelAsRequired() {
            if (this.forceRequiredLabel)
                return true;
            for (let error of this.errors) {
                if (Object.prototype.hasOwnProperty.call(error, "validationKey") && error.validationKey === "required")
                    return true;
            }
            return false;
        }

        setLabelForAttributeValue() {
            if (this.labelFor) {
                this.labelForAttributeValue = this.labelFor;
                return;
            }

            let childFormComponents = this.$children.filter(child => {
                return (child.$el.localName === "input" || child.$el.localName === "select") && child.$el.id;
            });

            if (childFormComponents.length > 0) {
                this.labelForAttributeValue = childFormComponents[0].$el.id;
                return;
            }

            return "";
        }
    }
