import Vue from 'vue';

//font icons
import 'font-awesome/scss/font-awesome.scss';
import 'open-iconic/font/css/open-iconic-bootstrap.css';

import './styles/main.scss';

import 'vue-router';
import './register-hooks';

//bootstrap-vue
import BootstrapVue from 'bootstrap-vue/esm/index.js';

//common plug-ins

//axios
import axios from 'axios';
import VueAxios from 'vue-axios';

//Auth
import AuthManager from './auth/index';

//vuelidate
import Vuelidate from 'vuelidate';
import VuelidateErrorExtractor from 'vuelidate-error-extractor';

//MultiSelect
import MultiSelect from 'vue-multiselect';
import "vue-multiselect/dist/vue-multiselect.min.css";

//DataTables
import $ from 'jquery';
import dt from 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';

import DataTables, { PluginOptions, FiltersGroup } from '@entrypointdev/datatables.net-vue';

//router and store
import router from '@/routes/router';
import store from '@/store';

//filters
import Filters from './utils/filters';

//other
import { sync } from 'vuex-router-sync';
import VueProgressBar from 'vue-progressbar';
import Toasted from 'vue-toasted';

import rootMixin from '@/mixins/root-component-mixin';

//local components
import baseLayout from '@/components/views/base-layout.vue';
import loadingButton from '@/components/common/loading/loading-btn.vue';
import validationSummary from '@/components/common/forms/validation-summary.vue';
import formGroupTemplate from '@/components/common/forms/form-group-template.vue';
import DateRangeSelect from '@/components/common/forms/date-range-select.vue';
import DataTablesFilterInput from '@/components/common/datatables/filters/filter-input.vue';
import DataTablesFilterDateRange from '@/components/common/datatables/filters/filter-date-range.vue';
import DataTablesFilterCheckbox from '@/components/common/datatables/filters/filter-checkbox.vue';
import DataTablesFilterSelect from '@/components/common/datatables/filters/filter-select.vue';
import { dataTablesDefaults, dataTablesClasses } from '@/components/common/datatables/defaults';
import VueGoogleCharts from 'vue-google-charts';

sync(store, router);

Vue.use(BootstrapVue);

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = '/api/';

Vue.use(AuthManager, { router: router });

Vue.use(Vuelidate);

Vue.use(VuelidateErrorExtractor, {
    name: 'form-group',
    template: formGroupTemplate,
    messages: {
        required: 'The {label} field is required.',
        email: 'The {label} field should be a valid email.',
        confirmPassword: 'The passwords should match.',
        minLength: 'The {label} must be at least {min} characters long.'
    }
});

Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)'
});

Vue.use(VueGoogleCharts);

dt(window, $);

Vue.use(DataTables, { jQuery: $, defaults: dataTablesDefaults });

Vue.use(Toasted, {
    theme: "bubble",
    position: "top-center",
    iconPack: 'fontawesome',
    duration: 1500
});

//filters
for (let filter in Filters) {
    Vue.filter(filter, Filters[filter]);
}

Vue.component('btn-loading', loadingButton);
Vue.component('validation-summary', validationSummary);

Vue.component('datatable-filters-group', FiltersGroup);
Vue.component('datatable-filter-input', DataTablesFilterInput);
Vue.component('datatable-filter-date-range', DataTablesFilterDateRange);
Vue.component('datatable-filter-checkbox', DataTablesFilterCheckbox);
Vue.component('datatable-filter-select', DataTablesFilterSelect);
Vue.component('multiselect', MultiSelect);
Vue.component('date-range-select', DateRangeSelect);

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(baseLayout),
    mixins: [rootMixin]
});