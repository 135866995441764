
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    @Component
    export default class ValidationSummary extends Vue {
        @Prop({ type: [String, Array], required: true, default: () => { return []; } })
        errors!: string | [];
        @Prop({ type: Boolean })
        success!: boolean;
        errorsArr: string | string[] = '';
        @Watch('errors')
        errorsWatcher() {
            this.errorsArr = Array.isArray(this.errors) ? this.errors : [this.errors];
        }
    }
