
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import RootComponentModel from '@/types/common/root-component-model';
    import { DateRangeFilterMixin } from '@entrypointdev/datatables.net-vue';
    import { DATE_FORMAT, DEFAULT_RANGES, DATE_RANGE_SPLITTER } from '@/constants/dates';

    @Component
    export default class FilterDateRange extends Mixins(DateRangeFilterMixin) {
        readonly $root!: RootComponentModel;

        //dateRange - mixed from mixin (see source)

        @Prop({ type: [Object, Boolean], default: () => DEFAULT_RANGES })
        protected ranges!: object | boolean;

        //used by mixin (see source)
        @Prop({ type: String, default: DATE_FORMAT })
        protected dateFormat!: string;

        //used by mixin (see source)
        @Prop({ type: String, default: DATE_RANGE_SPLITTER })
        protected dateRangeSplitter!: string;

        @Prop({ type: String, default: 'right' })
        protected opens!: string;

        @Prop({ type: String, default: 'Date range' })
        protected placeholder!: string;

        @Prop({ type: String, default: 'form-control form-control-sm' })
        protected inputClass!: string;
    }
