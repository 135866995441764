
    import { Component, Vue } from 'vue-property-decorator';
    import NavMenu from '@/components/views/nav-menu.vue';

    @Component({
        components: {
            'nav-menu': NavMenu
        }
    })
    export default class BaseLayout extends Vue {
        successToast(message) {
            Vue.toasted.success(message, { icon: 'check' });
        }

        errorToast(message) {
            Vue.toasted.error(message, { icon: 'exclamation' });
        }

        get isAuthenticated() {
            return this.$auth.isAuthenticated;
        }
    }
