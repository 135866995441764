export enum Role {
    SuperAdmin = 1,
    DistrictAdmin,
    BuildingAdmin
}

export const RoleNames = new Map<number, string>([
    [Role.SuperAdmin, 'Super Admin'],
    [Role.DistrictAdmin, 'District Admin'],
    [Role.BuildingAdmin, 'Building Admin'],
]);